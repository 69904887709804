import React, { FunctionComponent, ReactNode } from 'react';
import type { MainTitleType } from 'shared/types';

import { getDisplayShortcutKeys } from '../../../utils/shortcuts/shortcuts';
import styles from '../CommandPalette.module.css';
// eslint-disable-next-line import/no-cycle
import { CmdAction, useCmdAction } from './PaletteWrapper';

export type PaletteAction = {
  focused: false;
  label?: string;
  uniqueId?: string; // this is used as key prop, defaults to `label` (see MouseFocusTracker for example)
  // These are not used within actions typically, but for the action search
  tags?: string[];
  clickable?: boolean;
  group?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  mainTitleType?: MainTitleType;
  shortcut?: string | string[];
};

export type InternalPaletteAction = {
  action?: CmdAction;
  children: ReactNode;
  childrenClassName?: string;
  childrenTitle?: string;
  clickable?: boolean;
  focused: boolean;
  icon?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  mainTitleType?: MainTitleType;
  shortcut?: string | string[];
};

export const PaletteAction: FunctionComponent<InternalPaletteAction> = ({
  focused,
  action,
  children,
  shortcut,
  icon,
  clickable = true,
  childrenClassName = '',
  childrenTitle = '',
}: InternalPaletteAction) => {
  useCmdAction(action, focused);
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`palette-action-row ${styles.paletteActionRow} ${
        focused && clickable ? `${styles.focused} focused` : ''
      } ${!clickable ? `${styles.notClickable}` : ''} `}
      onClick={action}
    >
      <span className={childrenClassName} title={childrenTitle}>
        {children}
      </span>
      {shortcut ? (
        <span className={styles.actionKey}>{getDisplayShortcutKeys(shortcut)}</span>
      ) : undefined}
      {icon && <span className={styles.actionIcon}>{icon}</span>}
    </div>
  );
};

export const PaletteGroup = ({
  title,
  children,
}: { title: string; children: ReactNode[] | ReactNode }) => {
  return (
    <div>
      <div className={styles.paletteLabel}>{title}</div>
      {children}
    </div>
  );
};
