import React, { useCallback, useMemo } from 'react';
import ttsController from 'shared/foreground/actions/ttsController.platform';
import { setCmdPaletteOpen } from 'shared/foreground/cmdPalette';
import { globalState } from 'shared/foreground/models';
import useOpenDocumentId from 'shared/foreground/stateHooks/useOpenDocumentId';
import { FirstClassDocument } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';
import capitalize from 'shared/utils/capitalize';
import getDocumentTitle from 'shared/utils/getDocumentTitle';

import playOrStopTtsFromCurrentScrollPosition from '../../utils/playOrStopTtsFromCurrentScrollPosition';
import { useShortcutsMap } from '../../utils/shortcuts';
import { PaletteAction } from './Base/PaletteAction';

export const TtsAutoScrollAction = React.memo(function TtsAutoScrollAction({ focused }: PaletteAction) {
  const isEnabled = globalState((state) => Boolean(state.tts?.autoScrolling));

  const action = useCallback(async () => {
    await Promise.all([
      ttsController.setIsAutoScrollingEnabled(!isEnabled),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  }, [isEnabled]);

  const verb = isEnabled ? 'Disable' : 'Enable';

  return (
    <PaletteAction action={action} focused={focused}>
      {verb} auto-scrolling
    </PaletteAction>
  );
});

export const TtsJumpAction = React.memo(function TtsJumpAction({
  direction,
  focused,
}: PaletteAction & {
  direction: 'backward' | 'forward';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () => shortcutsMap[direction === 'backward' ? ShortcutId.SkipBackwards : ShortcutId.SkipForward],
    [direction, shortcutsMap],
  );

  const action = async () => {
    await Promise.all([
      ttsController[`jump${capitalize(direction)}`](),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return (
    <PaletteAction action={action} focused={focused} shortcut={shortcut}>
      Jump {direction}s
    </PaletteAction>
  );
});

export const TtsResumeOrPauseAction = React.memo(function TtsResumeOrPauseAction({
  focused,
}: PaletteAction) {
  const shortcutsMap = useShortcutsMap();

  const action = async () => {
    await Promise.all([
      ttsController.resumeOrPauseCurrentlyPlayingDocument(),
      setCmdPaletteOpen(false, { userInteraction: 'unknown' }),
    ]);
  };

  return (
    <PaletteAction action={action} focused={focused} shortcut={shortcutsMap[ShortcutId.PlayOrPause]}>
      Pause / resume
    </PaletteAction>
  );
});

export const TtsPlayrateAction = React.memo(function TtsPlayrateAction({
  actionName,
  focused,
}: PaletteAction & {
  actionName: 'increase' | 'decrease';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () =>
      shortcutsMap[actionName === 'increase' ? ShortcutId.SpeedUpPlayback : ShortcutId.SlowDownPlayBack],
    [actionName, shortcutsMap],
  );

  const action = async () => {
    const userInteraction = 'unknown';
    await Promise.all([
      ttsController[`${actionName}PlaybackRatePreference`]({ userInteraction }),
      setCmdPaletteOpen(false, { userInteraction }),
    ]);
  };

  return (
    <PaletteAction action={action} focused={focused} shortcut={shortcut}>
      {capitalize(actionName)} playback rate
    </PaletteAction>
  );
});

export const TtsStartAction = React.memo(function TtsStartAction({
  focusedDocument,
  focused,
}: PaletteAction & {
  focusedDocument: FirstClassDocument;
}) {
  const openDocumentId = useOpenDocumentId();
  const action = useCallback(async () => {
    let playPromise: Promise<unknown>;
    if (openDocumentId) {
      playPromise = playOrStopTtsFromCurrentScrollPosition({
        currentlyOpenDocId: openDocumentId,
      });
    } else {
      playPromise = ttsController.playDocument(focusedDocument.id);
    }

    await Promise.all([playPromise, setCmdPaletteOpen(false, { userInteraction: 'unknown' })]);
  }, [focusedDocument.id, openDocumentId]);

  return (
    <PaletteAction action={action} focused={focused}>
      Start for &quot;{getDocumentTitle(focusedDocument)}&quot;
    </PaletteAction>
  );
});

export const TtsStopAction = React.memo(function TtsStopAction({ focused }: PaletteAction) {
  const shortcutsMap = useShortcutsMap();

  const action = async () => {
    await Promise.all([ttsController.stop(), setCmdPaletteOpen(false, { userInteraction: 'unknown' })]);
  };

  return (
    <PaletteAction action={action} focused={focused} shortcut={shortcutsMap[ShortcutId.Stop]}>
      Stop and hide player
    </PaletteAction>
  );
});

export const TtsVolumeAction = React.memo(function TtsVolumeAction({
  actionName,
  focused,
}: PaletteAction & {
  actionName: 'increase' | 'decrease';
}) {
  const shortcutsMap = useShortcutsMap();
  const shortcut = useMemo(
    () =>
      shortcutsMap[actionName === 'increase' ? ShortcutId.IncreaseVolume : ShortcutId.DecreaseVolume],
    [actionName, shortcutsMap],
  );

  const action = async () => {
    const userInteraction = 'unknown';
    await Promise.all([
      ttsController.modifyVolumePreference(actionName, { userInteraction }),
      setCmdPaletteOpen(false, { userInteraction }),
    ]);
  };

  return (
    <PaletteAction action={action} focused={focused} shortcut={shortcut}>
      {capitalize(actionName)} volume
    </PaletteAction>
  );
});
